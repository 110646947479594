'use strict';

let lenis = new Lenis();

document.addEventListener('DOMContentLoaded', function () {
  // ヘッダーの高さを取得する関数
  function getHeaderHeight() {
    var header = document.querySelector('header');
    return header ? header.offsetHeight : 0;
  }

  // アンカーリンクのクリック時
  document.querySelectorAll('a[href^="#"]').forEach(function (anchor) {
    anchor.addEventListener('click', function (event) {
      event.preventDefault();

      var targetId = this.getAttribute('href').substring(1); // href="#example" -> example
      var targetElement = document.getElementById(targetId);

      if (targetElement) {
        var headerHeight = getHeaderHeight();
        var targetPosition =
          targetElement.getBoundingClientRect().top +
          window.pageYOffset -
          headerHeight;

        // スムーズスクロール
        window.scrollTo({
          top: targetPosition,
          behavior: 'smooth',
        });
      }
    });
  });

  // ページロード時にURLにハッシュがある場合にスクロール調整
  window.addEventListener('load', function () {
    var hash = window.location.hash;
    if (hash) {
      var targetElement = document.getElementById(hash.substring(1)); // ハッシュからIDを取得
      if (targetElement) {
        var headerHeight = getHeaderHeight();
        var targetPosition =
          targetElement.getBoundingClientRect().top +
          window.pageYOffset -
          headerHeight;

        // ページロード時にスクロール位置を即座に調整
        window.scrollTo({
          top: targetPosition,
          behavior: 'instant', // ページ読み込み時は即座にジャンプ
        });
      }
    }
  });
});

function raf(time) {
  lenis.raf(time);
  requestAnimationFrame(raf);
}

requestAnimationFrame(raf);

document.addEventListener('DOMContentLoaded', function () {
  const currentUrl = window.location.pathname;

  // リスト項目の処理
  const listItems = document.querySelectorAll('.is_linkList li');
  listItems.forEach(function (item) {
    const link = item.querySelector('a').getAttribute('href');
    if (link !== '/about-kta/history/#access' && link === currentUrl) {
      item.style.display = 'none';
    }
  });

  // <p>タグ内のリンクの処理
  const hdAreaLink = document.querySelector('.aboutListHd a');
  if (hdAreaLink && hdAreaLink.getAttribute('href') === currentUrl) {
    hdAreaLink.parentElement.style.display = 'none';
  }
});

document.addEventListener('DOMContentLoaded', (event) => {
  const copyLinkButton = document.getElementById('copyLinkButton');

  copyLinkButton.addEventListener('click', function (e) {
    e.preventDefault();

    const linkToCopy = copyLinkButton.getAttribute('data-link');
    const tempInput = document.createElement('input');
    tempInput.value = linkToCopy;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);

    alert('リンクがコピーされました: ' + linkToCopy); // 通知を表示（オプション）
  });
});

document.addEventListener('DOMContentLoaded', function () {
  var input = document.getElementById('placeholderEvent');

  // フォーカス時にプレースホルダーを消す
  input.addEventListener('focus', function () {
    input.setAttribute('data-placeholder', input.getAttribute('placeholder'));
    input.setAttribute('placeholder', '');
  });

  // フォーカスが外れたときにプレースホルダーを再表示
  input.addEventListener('blur', function () {
    input.setAttribute('placeholder', input.getAttribute('data-placeholder'));
  });
});

document.addEventListener('DOMContentLoaded', function () {
  // リセットボタンをクリックしたときの処理
  var resetButton = document.getElementById('reset-button');
  if (resetButton) {
    resetButton.addEventListener('click', function () {
      // クエリパラメータなしのページにリダイレクト
      location.href = '<?php echo esc_url(get_home_url()) . ' / news / '; ?>';
    });
  }
});

document.addEventListener('DOMContentLoaded', function () {
  // 全ての.js_moreBtnを取得
  const moreButtons = document.querySelectorAll('.js_moreBtn .wp-block-button');

  // 各ボタンにクリックイベントを追加
  moreButtons.forEach(function (button) {
    button.addEventListener('click', function () {
      // ボタンの親要素である.js_moreBtnを取得
      const moreArea = this.closest('.js_moreBtn');
      if (moreArea) {
        moreArea.classList.add('close');
      }
    });
  });
});

document.addEventListener('DOMContentLoaded', function () {
  // 全ての dt 要素を取得
  const headings = document.querySelectorAll('#accordion');

  // 各 dt 要素にクリックイベントを追加
  headings.forEach(function (heading) {
    heading.addEventListener('click', function () {
      // クリックされた dt に対応する dd 要素を取得
      const content = heading.nextElementSibling;

      // dd 要素の表示/非表示を切り替え
      if (content.style.display === 'none' || content.style.display === '') {
        content.style.display = 'block';
        heading.classList.add('open'); // 'open' クラスを追加
      } else {
        content.style.display = 'none';
        heading.classList.remove('open'); // 'open' クラスを削除
      }
    });
  });
});

function initializeSwiper(
  containerClass,
  nextButtonClass,
  prevButtonClass,
  scrollbarClass
) {
  return new Swiper(containerClass, {
    slidesPerView: 'auto',
    spaceBetween: 8,
    breakpoints: {
      840: {
        spaceBetween: 12,
      },
    },
    navigation: {
      nextEl: nextButtonClass,
      prevEl: prevButtonClass,
    },
    scrollbar: {
      el: scrollbarClass,
      // hide: false,
      // draggable: true,
    },
  });
}

const swiperCount = 3; // 現在のスワイパーの数。必要に応じて増やしてください。

for (let i = 1; i <= swiperCount; i++) {
  const swiperContainer = `.swiper-container0${i}`;
  const nextButton = `.swiper-button-next0${i}`;
  const prevButton = `.swiper-button-prev0${i}`;
  const scrollbar = `.swiper-scrollbar0${i}`;

  initializeSwiper(swiperContainer, nextButton, prevButton, scrollbar);
}

const swiper_4col01 = new Swiper('.swiper-4col01', {
  slidesPerView: 'auto',
  spaceBetween: 8,
  breakpoints: {
    840: {
      slidesPerView: '4',
      spaceBetween: 12,
    },
  },
  navigation: {
    nextEl: '.swiper-4col-button-next01',
    prevEl: '.swiper-4col-button-prev01',
  },
  scrollbar: {
    el: '.swiper-4col-scrollbar01',
    // hide: false,
    // draggable: true,
  },
});

const swiper_note = new Swiper('.swiper-note', {
  slidesPerView: 'auto',
  spaceBetween: 8,
  breakpoints: {
    840: {
      slidesPerView: '4',
      spaceBetween: 12,
      scrollbar: false,
    },
  },
  // navigation: {
  //   nextEl: '.swiper-note-next',
  //   prevEl: '.swiper-note-prev',
  // },
  scrollbar: {
    el: '.swiper-note-scrollbar',
    // hide: false,
    // draggable: true,
  },
});

const swiper_projects = new Swiper('.swiper-projects', {
  slidesPerView: 'auto',
  spaceBetween: 8,
  breakpoints: {
    840: {
      slidesPerView: '4',
      spaceBetween: 12,
      scrollbar: false,
    },
  },
  scrollbar: {
    el: '.swiper-projects-scrollbar',
  },
});

const swiper_joinUs = new Swiper('.swiper-joinUs', {
  slidesPerView: 'auto',
  spaceBetween: 8,
  breakpoints: {
    840: {
      slidesPerView: '4',
      spaceBetween: 12,
      scrollbar: false,
    },
  },
  scrollbar: {
    el: '.swiper-joinUs-scrollbar',
  },
});

function initializeSwiper02(
  containerClass02,
  nextButtonClass02,
  prevButtonClass02,
  scrollbarClass02
) {
  return new Swiper(containerClass02, {
    slidesPerView: 'auto',
    spaceBetween: 8,
    watchOverflow: true,
    paginationClickable: true,
    breakpoints: {
      1400: {
        // watchOverflow: true,
        // paginationClickable: true,
        slidesPerView: '4',
        spaceBetween: 12,
      },
    },
    navigation: {
      nextEl: nextButtonClass02,
      prevEl: prevButtonClass02,
    },
    scrollbar: {
      el: scrollbarClass02,
      // hide: false,
      // draggable: true,
    },
    onSlideChangeStart: function () {
      $('.swiper-slide').addClass('swiper-moving');
    },
    onSlideChangeEnd: function () {
      $('.swiper-slide').removeClass('swiper-moving');
    },
  });
}
const swiperCount02 = 4; // 現在のスワイパーの数。必要に応じて増やしてください。
for (let i = 1; i <= swiperCount02; i++) {
  const swiperContainer02 = `.swiper-service0${i}`;
  const nextButton02 = `.swiper-button-service-next0${i}`;
  const prevButton02 = `.swiper-button-service-prev0${i}`;
  const scrollbar02 = `.swiper-service-scrollbar0${i}`;

  initializeSwiper02(
    swiperContainer02,
    nextButton02,
    prevButton02,
    scrollbar02
  );
}
